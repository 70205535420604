import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

Button.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
};

function Button(props) {

    const { link, title } = props;
    return (
        <a href={link} className="action-btn" target="_blank" rel="noopener noreferrer"><span>{title}</span></a>
    );
}

export default Button;