

const dataRoadMap = [

    {
        id: 1,
        time: 'February 01, 2022',
        title: 'Idea Generation',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
        class: ''
    },
    {
        id: 2,
        time: 'February 01, 2022',
        title: 'Design & Development',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
        class: 'right'
    },

    {
        id: 3,
        time: 'February 01, 2022',
        title: 'Initial Release',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
        class: ''
    },

    {
        id: 4,
        time: 'February 01, 2022',
        title: 'Result & Final Report',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
        class: 'right'
    },
    {
        id: 5,
        time: 'February 01, 2022',
        title: 'Design & Development',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis diam enim, scelerisque sit amet diam ut, molestie ultrices nisi. Suspendisse in ipsum ante. Ut rhoncus ligula dictum gravida.',
        class: ''
    },



]

export default dataRoadMap;