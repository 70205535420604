import React, { useState } from 'react';


import Button from '../button/Button';

import img1 from '../../assets/images/layouts/about1.png'
import img2 from '../../assets/images/layouts/about2.png'

import avt from '../../assets/images/layouts/avt-05.png'






function About3(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'Cube x3',
            desc1: '',
            desc2: 'CubeX3, a division of Xphyre, Inc., is transforming the gaming industry with a focus on 2D and 3D game development for mobile gaming, along with AAA strategy games and immersive experiences. Our innovative approach integrates the metaverse, virtual reality, and real human experiences to create dynamic strategic gameplay. CubeX3 is also exploring brick-and-mortar gaming areas, blending physical and digital worlds. Utilizing blockchain and crypto, we are pioneers in play-to-earn (P2E) models, providing players with opportunities to engage in virtual economies with real-world value.'

        }
    )
    const [dataCard] = useState(
        [
            {
                id: 1,
                img: img1,
                title: '3D Digital Artwork',
                price: '2.26 ETH',
                avt: avt,
                name: 'Leslie Alexander',
                tag: '@leslie754'
            },
            {
                id: 2,
                img: img2,
                title: '3D Digital Artwork',
                price: '2.26 ETH',
                avt: avt,
                name: 'Leslie Alexander',
                tag: '@leslie754',
                class: 'sm'
            },
        ]
    )
    return (
        <section className="about s3">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text pd-0">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading">{dataBlock.heading}</h3>
                            <p className="mb-17">{dataBlock.desc1}</p>
                            <p className="mb-26">{dataBlock.desc2}</p>
                            <Button title='Work with us' link='https://www.xphyre.com/contact' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="about__right" data-aos="fade-left" data-aos-duration="2000">

                            {
                                dataCard.map(idx => (
                                    <div key={idx.id} className={`card-box ${idx.class}`}>
                                        <div className="top d-flex">
                                            <span className="icon-logo-01"></span>
                                            {/* <div>
                                                <h6>{idx.title}</h6>
                                                <h6 className="price">{idx.price}</h6>
                                            </div> */}
                                        </div>
                                        <div className="content">
                                            <div className="image">
                                                <img src={idx.img} alt="Cyfonii" />
                                            </div>

                                            {/* <div className="info d-flex">
                                                <img src={idx.avt} alt="Cyfonii" />

                                                <div >
                                                    <h6 className="name">{idx.name}</h6>
                                                    <p>{idx.tag}</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default About3;