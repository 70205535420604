import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo/logoContrax.png'



function Footer(props) {

    const [dataBlock] = useState(
        {
            heading: 'Get Newsletter',
            desc: 'Stay up to date with CubeX3'
        }
    )

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <footer className="footer">

            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer__main">
                            <div className="block-text center">

                                <h3 className="heading">{dataBlock.heading}</h3>
                                <p >{dataBlock.desc}</p>


                            </div>
                            <form action="#" className="form">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Your email" />

                                </div>
                                <button className="action-btn"><span>Subscribe</span></button>
                            </form>
                        </div>

                        <div className="footer__bottom" style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <div className="center" style={{ textAlign: 'center' }}>
                                <Link to="/">
                                    <img id="site-logo" src={logo} alt="cyfonii" style={{ height: '60px', width: 'auto' }} />
                                </Link>
                            </div>
                            <p style={{ marginTop: "10px" }}>Powered by Xphyre</p>
                        </div>

                    </div>
                </div>
            </div>

            {
                isVisible &&
                <Link to='#' onClick={scrollToTop} id="scroll-top"><span className="icon-arrow-top"></span></Link>
            }
        </footer>
    );
}

export default Footer;