import React from 'react';
import Banner3 from '../components/banner/Banner3';
import About3 from '../components/about/About3';

import Footer from '../components/footer/Footer';


function Home03(props) {
    return (
        <div className='home-3 wrapper'>
          
        <Banner3 />

        <About3 />

        {/* <Token /> */}

        {/* <Project data={dataProject} /> */}

        {/* <Roadmap2 data={dataRoadMap} /> */}


        {/* <Team2 data={dataTeam} /> */}

        {/* <Speciality data={dataBox} /> */}

        {/* <Testimonials data={dataTestimonials} /> */}

        {/* <Counter />   */}

        {/* <Blog data={dataBlog} /> */}

        {/* <Faq3 data={dataFaqs} /> */}


        <Footer />

        </div>
    );
}

export default Home03;